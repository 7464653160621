<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >
    <b-card
      :title="`${$t('Staff')} ${$t('To Edit')}`"
    >

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-row>
            <!-- Field: Staff Name -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="name"
                rules="required"
              >
                <b-form-group
                  :label="$t('Name')"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="staffData.name"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Phone -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="phone"
                rules="integer"
              >
                <b-form-group
                  :label="$t('Phone')"
                  label-for="phone"
                >
                  <b-form-input
                    id="phone"
                    v-model="staffData.phone"
                    class="form-control-merge"
                    :state="getValidationState(validationContext)"
                    name="phone"
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>

              </validation-provider>
            </b-col>

            <!-- Field: User -->
            <b-col
              cols="12"
              md="4"
            >
              <!-- User -->
              <validation-provider
                #default="validationContext"
                name="user"
                rules="required"
              >
                <b-form-group
                  :label="$t('User')"
                  label-for="user_id"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="staffData.user_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="userOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="user_id"
                    disabled
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

            <!-- Field: Branch -->
            <b-col
              cols="12"
              md="4"
            >
              <!-- Branch -->
              <validation-provider
                #default="validationContext"
                name="branch"
                rules="required"
              >
                <b-form-group
                  :label="$t('Branch')"
                  label-for="branch_id"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="staffData.branch_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="branchOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="branch_id"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

            <!-- Field: Sale Region -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('Sale Region')"
                label-for="sale-region"
              >
                <v-select
                  v-model="staffData.sale_region_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="saleRegionOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="sale_region_id"
                  placeholder="Only for sale staffs"
                />

              </b-form-group>

            </b-col>

            <!-- Field: Status -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="status"
                rules="required"
              >
                <b-form-group
                  :label="$t('Status')"
                  label-for="status"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="staffData.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="statusOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="status"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Comment -->
            <b-col
              md="4"
            >
              <b-form-group
                :label="$t('Comment')"
                label-for="comment"
              >
                <b-form-input
                  id="comment"
                  v-model="staffData.comment"
                  trim
                  placeholder=""
                />
              </b-form-group>
            </b-col>

          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <feather-icon icon="SaveIcon" />&nbsp;
              {{ $t('Save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="cancel"
            >
              <feather-icon icon="XIcon" />&nbsp;
              {{ $t('Cancel') }}
            </b-button>
          </div>

        </b-form>

      </validation-observer>

    </b-card>
  </b-overlay>
</template>

<script>

import {
  BOverlay, BRow, BCol, BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from '@/libs/vue-select'
import router from '@/router/index'
import store from '@/store'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import storeModule from '../../../common/storeModule'
import staffStoreModule from '../staffStoreModule'

export default {
  components: {
    BOverlay,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BRow,
    BCol,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {

  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  computed: {
  },
  methods: {
    cancel() {
      this.$router.push({ name: 'staff-list' })
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'staff'

    // Register module
    if (!store.hasModule('common')) store.registerModule('common', storeModule)
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, staffStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const usersArray = []
    const userOptions = ref([])
    store.dispatch('common/fetchUsers')
      .then(response => {
        const { users } = response.data.data
        for (let i = 0; i < users.length; i += 1) {
          usersArray.push({ label: users[i].name, value: users[i].id })
        }
        userOptions.value = usersArray
      })

    const branchesArray = []
    const branchOptions = ref([])
    store.dispatch('common/fetchBranches')
      .then(response => {
        const { branches } = response.data.data
        for (let i = 0; i < branches.length; i += 1) {
          branchesArray.push({ label: branches[i].name, value: branches[i].id })
        }
        branchOptions.value = branchesArray
      })

    const saleRegionsArray = []
    const saleRegionOptions = ref([])
    store.dispatch('common/fetchSaleRegions')
      .then(response => {
        const { sale_regions } = response.data.data
        for (let i = 0; i < sale_regions.length; i += 1) {
          saleRegionsArray.push({ label: sale_regions[i].name, value: sale_regions[i].id })
        }
        saleRegionOptions.value = saleRegionsArray
      })

    const statusOptions = [
      { label: 'Active', value: 'Active' },
      { label: 'Inactive', value: 'Inactive' },
    ]

    const blankStaffData = {
      name: '',
      phone: '',
      profile_image: '',
      user_id: null,
      branch_id: null,
      sale_region_id: null,
      status: '',
      comment: '',
    }

    const staffData = ref(JSON.parse(JSON.stringify(blankStaffData)))
    const staffId = router.currentRoute.params.id
    store.dispatch('staff/fetchOne', { id: staffId })
      .then(response => {
        const responseStaffData = {
          id: response.data.data.id,
          name: response.data.data.name,
          phone: response.data.data.phone,
          user_id: response.data.data.user_id,
          branch_id: response.data.data.branch_id,
          sale_region_id: response.data.data.sale_region_id,
          status: response.data.data.status,
          comment: response.data.data.comment,
        }
        staffData.value = responseStaffData
      })
      .catch(error => {
        if (error.response.status === 404) {
          staffData.value = undefined
        }
      })

    const onSubmit = () => {
      const data = JSON.parse(JSON.stringify(staffData.value))
      store.dispatch('staff/edit', data)
        .then(response => {
          if (response.status === 200) {
            router.push({ name: 'staff-list' })
          }
        })
        .catch(error => {
          window.swal('Error', JSON.stringify(error.response.data).replace(/"([^"]+)":/g, '$1:'))
        })
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      staffData,
      onSubmit,
      userOptions,
      branchOptions,
      saleRegionOptions,
      statusOptions,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
